const privacyData = {
    'cn': [
        {
          "topTitle":"《少年西行记》隐私条款"
        },
        {
            "title": '前言',
           "content": [
               '本政策是由我方平台（以下称为“甲方”）就甲方在其和/或其关联公司的移动手机游戏平台上所提供的产品和服务（包括甲方的网站以及甲方现在正在提供和将来可能向用户提供的游戏服务和其他网络服务，以下统称为“产品和服务”）所制定的。',
               '最近更新日期：2022年10月31日',
               `本政策将帮助您了解以下内容：`,
               `一、我们如何收集和使用个人信息`,
               `二、我们如何使用 Cookie 和同类技术`,
               `三、我们如何共享、转让、公开披露个人信息`,
               `四、我们如何保护您的个人信息储存`,
               '五、第三方服务',
               `六、我们如何处理未成年人的个人信息`,
               `七、您的个人信息如何在全球范围转移`,
               `八、本政策可能变更`,
               `九、如何联系我们`,
               '在使用我们的服务前，您可以通过阅读完整版《少年西行记隐私政策》，了解个人信息类型与用途的对应关系等更加详尽的个人信息处理规则，请您务必仔细阅读并透彻理解本政策，您应重点阅读并在确认充分理解及同意后再开始使用。如果您继续使用我们的服务，即表示您同意我们按照本政策收集、使用、储存和披露您的相关信息。',
               '请注意，甲方可能适时对本协议进行修改与更新，您同意将随时留意查看本协议的最新版本。请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私政策》，以确保对本《隐私政策》的最新版本始终保持了解。',
           ]
        },
        {
            'title': '一、我们如何收集和使用个人信息',
            "content": [
                '1.个人信息',
                '当您使用服务时，我们会收集您的网络身份标识信息及个人常用设备信息，用于标记您为少年西行记的用户。为完成创建账号所填写的信息，例如：您的手机号码、身份证、创建的用户名。第三方可能会获取您的社交帐号信息。',
                '2.为您提供您可能感兴趣的广告、资讯及产品信息',
                '为向您提供与您更加相关的广告以替代普遍投放的广告 ，我们可能会收集和使用您在甲方的操作记录和部分必要的设备信息（例如：您的唯一设备识别码），以及您在使用我们服务时浏览或要求提供的其他信息和内容详情、游戏账号交易信息。',
                '3.支付结算',
                '为便于您在支付相关订单时，综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，我们可能会在交易过程中收集您的虚拟财产相关信息（仅限交易记录、虚拟货币、虚拟交易、游戏类兑换码等）。',
                '4.消费记录',
                '当您使用少年西行记产品的消费功能时，我们可能会收集您的充值记录、消费记录信息，以便您查询您的交易记录，同时尽最大程度保护您的虚拟物品安全。充值记录、消费记录属于敏感信息，收集上述信息为实现少年西行记的消费功能所必须，否则将无法完成交易。',
                '5.产品开发和服务优化。',
                '例如，为修复系统故障并优化我们的服务，我们可能需记录和分析系统故障时产生的信息。',
                '6.可能会收集的信息',
                '为保障您正常使用我们的服务，维护游戏基础功能的正常运行，优化游戏产品性能，提升您的游戏体验并保障您的帐号安全，我们可能会收集：唯一设备识别符（IMEI）、MAC地址、广告标识符、Serial、设备机型、设备名称、操作系统及版本、设备分辨率、设备设置、软硬件特征信息、设备屏幕尺寸、移动网络信息、登录IP地址、日志信息（包括操作日志、服务日志）、CPU信息、软件信息。用于识别帐号异常状态，了解产品适配性，维护基础功能的正常运行，实现本游戏的问题排查。为了收集上述基本的 个人设备信息，我们将会申请访问您的设备信息权限，我们收集这些信息是为了向您提供本游戏的核心游戏功能。拒绝提供上述信息将可能使您无法正常体验本游戏的功能。',
                '7.第三方收集的信息',
                '请您理解，在从第三方接收您的个人信息前，我们无权决定且无法知悉第三方获取您个人信息的范围，如第三方开展业务所进行的个人信息处理活动超出您向我们提供个人信息时的授权同意范围、或第三方违法违规收集您的个人信息（如渠道违反必要原则，收集您的通讯录信息），责任应由第三方承担，请您立即和我们取得联系，以便我们及时通知第三方采取相应措施以避免或降低相关损失。',
            ]
        },
        {
            "title": "二、我们如何使用 Cookie 和同类技术",
            "content":[
                '为确保网站正常运转，我们可能会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。 Cookie有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息了解您使用服务进行什么活动、或哪些服务或服务最受欢迎；及有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。',
                '少年西行记上可能会有广告商和其他合作方放置的COOKIES或同类技术。这些COOKIES和或同类技术可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方COOKIES或同类技术收集和使用该等信息不受本政策约束，而是受到其自身的信息保护声明约束，我们不对第三方的COOKIES或同类技术承担责任。',
            ]

        },
        {
            "title": "三、我们如何共享、转让、公开披露您的个人信息",
            "content":[
                '（一）共享',
                '您理解并同意，经过去标识化处理后的信息将无法识别您的身份，我们可能在以下情况下与甲方以外的任何公司、组织和个人分享您的个人信息：',
                '1.与关联方的必要分享。为便于我们基于统一的帐号体系向您提供一致化服务以及便于您进行统一管理、个性化推荐、保障系统和帐号安全等，您的个人信息可能会在我们和我们的关联方之间进行必要共享；',
                '2.我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。',
                '3.向委托我们进行推广的合作伙伴等第三方共享，目的是为了使该等委托方了解推广的覆盖面和有效性。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客；',
                '4.与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。',
                '（二）转让',
                '我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：',
                '1.在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；',
                '2.在涉及合并、收购或破产清算时，如涉及个人信息转让，我们将通过推送通知、公告或其他方式另行告知您相关情形，按照法律法规所要求的标准继续保护或要求新的管理者按照本政策继续保护您的个人信息。',
                '3.部分场景下，您可以使用相同的账号及密码在其他第三方平台登录、使用，以获得更好的游戏体验。为提供此项服务，我们可能需要向第三方传送您的个人信息及游戏信息，我们将按照法律法规所要求的标准继续保护或要求新的管理者按照本政策继续保护您的个人信息。',
                '（三）公开披露',
                '我们仅会在以下情况下，公开披露您的个人信息：',
                '1.获得您明确同意后；',
                '2.与国家安全、国防安全有关的；',
                '3.与公共安全、公共卫生、重大公共利益有关的；',
                '4.与犯罪侦查、起诉、审判和判决执行等有关的；',
                '5.出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；',
                '6.所收集的信息是您自行向社会公众公开的；',
                '7.从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；',
                '8.根据您的要求签订合同所必需的；',
                '9.用于维护甲方服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；',
                '10.为合法的新闻报道所必需的；',
                '11.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；',
                '12.法律法规规定的其他情形。',
            ]

        },
        {
            "title": "四、我们如何保护您的个人信息",
            "content":[
                '（一）我们会采取一切合理可行的措施，保护您的个人信息。例如，我们使用SSL/hash对您的数据进行加密保护；如我们与第三方分享您的信息，我们可能将会采用加密、脱敏及其他去标识化处理等手段保障您的信息安全。',
                '（二）我们仅允许有必要知晓这些信息的甲方工作人员、合作伙伴访问您的信息，同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与甲方的合作关系。',
                '（三）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在必要的合理期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。',
                '（四）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他甲方用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。请您务必妥善保管好您的帐号、密码及其他身份要素。您在使用少年西行记时，我们会通过您的帐号、密码及及其他身份要素来识别您的身份。',
                '（五）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。',
                '（六）请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入少年西行记服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。',
            ]

        },{
            "title": "五、第三方服务",
            "content":[
                '少年西行记可能链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。使用SDK名称：友盟SDK，服务类型：社会化分享，收集个人信息类型：设备信（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/IP/地理位置） 还包括：（1）您可利用“分享”键将某些内容分享到第三方服务，或您可利用第三方服务登录少年西行记。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的电脑装置COOKIES，从而正常运行上述功能； （2）我们通过广告或我们服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站； （3）其他接入第三方服务的情形。包括：为实现本政策中声明的目的，我们可能会接入第三方SDK服务，并将我们依照本政策收集的您的个人信息如社交媒体信息，共享给该等第三方，以便提供更好的客户服务和用户体验。 ',
                '该等第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。 您发现这些第三方社交媒体或其他服务存在风险时，建议您终止相关操作以保护您的合法权益。 友盟隐私政策链接：https://www.umeng.com/page/policy'
            ]

        },
        {
            "title": "六、我们如何处理未成年人的个人信息",
            "content":[
                '我们的产品、网站和服务主要面向成人。甲方遵从国家政策，如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。对于经父母或监护人同意而收集未成年人个人信息的情况，我们只会在受到法律的允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。如发现在未事先获得法定监护人同意的情况下，我们可能收集了未成年人的个人信息，我们会对该等数据和个人信息尽快进行删除等操作处理。',
                '若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请及时与我们的客服联系。',
            ]

        },
        {
            "title": "七、您的个人信息如何在全球范围转移",
            "content":[
                '原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。',
                '我们可能通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。',
                '境外管辖区可能有不同的个人信息保护法，我们将对信息进行加密传输，并尽商业上合理努力督促第三方在使用您的个人信息时遵守其适用的法律法规及协议约定的保密和安全措施。',
            ]

        },
        {
            "title": "八、本政策可能变更",
            "content":[
                '为了给您提供更好的服务，我们可能会根据产品或服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。如该等更新造成您在本政策下权利的实质减少或重大变更，我们将在本政策生效前通过网站公告、推送通知、弹窗提示或其他方式来通知您，您如果不同意该等变更，可以选择停止使用少年西行记；如您仍然继续使用少年西行记，即表示您已充分阅读、理解并同意受经修订的本政策的约束。',
            ]

        },
        {
            "title": "九、如何联系我们",
            "content":[
                '我们设立了个人信息保护专职部门，将按照本政策保护您的个人信息。如您有关于网络信息安全的投诉和举报，或您对本政策、您的信息的相关事宜有任何问题、意见或建议，请与少年西行记的个人信息保护工作人员联系，我们将在15个工作日内回复。',
                `客服QQ：485729307`,
                `联系邮箱：485729307@qq.com`,
                `官方交流群: 220714787`,
            ]
        },
    ],
    'en': [
        {
            'topTitle':`Privacy policy`,
        },
        {
            'content':[`OUR COMMITMENT TO YOU`],
        },
        {
            'content':[
                 `At Clean Phone App, your privacy is a top priority. `,
                 `We appreciate that you put your trust in us when you provide us with your information and we do not take this lightly.`,
            ],

        },
        {
            'content':[`We do not compromise with your privacy. We design all of our products and services with your privacy in mind. We involve experts from various fields, including legal, security and others to make sure that no decision is taken without respect for your privacy.`],
        },
        {
            'content':[
              `We strive to be transparent in the way we process your data. `,
              `Because we use many of the same online services you do, we know that insufficient information and overly complicated language are common issues in privacy policies. `,
              `We take the exact opposite approach: we have written our Privacy Policy and related documents in plain language.`,
              `We actually want you to read our policies and understand our privacy practices!`
            ],
        },
        {
            'content':[`We work hard to keep your information secure. We have teams dedicated to keeping your data safe and secure. We constantly update our security practices and invest in our security efforts to enhance the safety of your information.`],
        },
        {
            'title': '1.INTRODUCTION',
            'content':[
                `Welcome to Clean Phone App’ Privacy Policy. `,
                `We appreciate that you trust us with your information and we intend to always keep that trust. This starts with making sure you understand the information we collect, why we collect it, how it is used and your choices regarding your information. This Policy describes our privacy practices in plain language, keeping legal and technical jargon to a minimum.`,
            ]
        },
        {
            'title': '2.WHERE THIS PRIVACY POLICY APPLIES',
            'content':[
                `This Privacy Policy applies to websites, apps and other services operated by Clean Phone App. For simplicity, we refer to all of these as our “services” or “apps” in this Privacy Policy. To make it extra clear, we’ve added links to this Privacy Policy on all applicable services.`,
            ]
        },
        {
            'title': '3.INFORMATION WE COLLECT',
            'content':[
                `t goes without saying, we can’t help you have an awesome user experience without some information about you, such as your email address and password in some of our services. We also collect information generated as you use our services, as well as information from third parties. Your information may be kept in an identifiable format, or in an aggregate format which means that you cannot reasonably be identified from it.`,
                `Information you give us`,
                `You choose to give us certain information when using our services. This includes:`,
                `When you create an account in some of our services, you provide us with your login credentials, such as email address, name and password.`,
                `To use certain features in our services, you may allow us to access your camera or the microphone. Depending on your device, Apple and Android system will ask for your permission before allowing us to access these features.`,
                `Features that allow you to share content about your use of our services with your connections or through your social network. We may also collect your information when you use our app such as when you post or share material, upload data, request further services or make selections using our app, if such functionality is available in the app.`,
                `If you contact our support team, we collect the information you give us when you report a problem, contact or communicate with us.`,
                `From time to time, we may request your opinions, suggestions, or modification and improvement ideas (“Feedback”) through surveys, or other similar requests. Any responses you provide are voluntary, however you agree that any Feedback you provide shall be owned Clean Phone App and may be used by Clean Phone App in our sole discretion.`,
                `Information we receive from others`,
                `In addition to the information you provide us directly, we receive information about you from others, including:`,
                `Social Media`,
                `You may be able to use your Facebook account to login to create an account. When you use this feature, we may receive any of your information you provide or permit to be provided to us via a social network or public forum provider like Facebook. This data may include your use of our app on such public forums and/or social networks, including with whom you share our app. For further information as to how and for what purpose the social network or public forum provider collects, uses and shares your data, as well as how you can set your privacy preferences on their site or apps, please see their privacy policy.`,
                `Other Partners`,
                `• We may receive information about you from our partners, for instance where our ads are published on a partner’s website or platform (in which case they may pass along details on a campaign’s success).`,
                `• If you purchase a subscription in our apps, neither Google nor Apple provide us with any personal information of the subscription purchaser (like name, email or payment information). Only anonymized data gets passed to Clean Phone.`,
                `Information collected when you use our servicesWhen you use our services, we collect information about which features you’ve used, how you’ve used them and the devices you use to access our services. See below for more details:Usage Information`,
                `We collect information about your activity on our services, for instance how you use them (e.g., date and time you logged in, time spent in the app, features you’ve been using, advertising that you click on).`,
                `Movement Data: If you opt-in, some services may collect movement data from your device.`,
                `Device information`,
                `We might collect information from and about the device(s) you use to access our services, `,
                `including:`,
                `geolocation data (in some of our apps), which is any information which indicates the `,
                `geographical position of your device and which may include the time the location was recorded, and may include precise (e.g., GPS-level) location data. Some apps may collect this data when the app is not open or actively in use. This may help us or third parties to customize information, content, or offers to you, for instance.`,
                `information on your wireless and mobile network connection, like your service provider and signal strength.`,
                `hardware and software information such as IP address, device type, app crashes, app version, advertising IDs (such as Google’s AdvertisingID and Apple’s IDFA, both of which are randomly generated numbers that you can reset by going into your device’s settings), browser type, version and language, operating system, time zones, identifiers associated with cookies or other technologies that may uniquely identify your device or browser (e.g., IMEI/UDID and MAC address).`,
            ]
        },
        {
            'title': '4.COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES',
            'content':[
                `As described in Sections 3 and 5 of this Privacy Policy, we sometimes work with third parties to deliver ads or offers. Sometimes we or these third parties do so through a mobile “SDK.” The SDK is a bit of computer code that app developers can include in their apps to enable data to be collected and ads to be shown. We may use this and similar technology to deliver certain advertising through mobile applications and browsers based on data associated with your mobile device, and we may work with third parties who likewise embed their SDKs into our services, for third party advertising. If you’d like to opt-out from having ads tailored to you in this way on your mobile device, please follow the instructions below or in the app (as applicable).`,
                `If you wish to opt out of interest-based tracking through your mobile device, you can do so through your device “settings”, as follows (but please note that the below is for informational purposes only and Apple, Google, or other device platforms may change the manner in which their “opt out” settings work at any time): Please note that if you opt out through these settings, you will continue to receive advertising after you opt-out, but it may not be tailored to your interests. For instance, that advertising may be based on your real-time or “contextual” application usage or general location inferred from IP address.`,
                `Opting Out on iOS Devices Such as iPhones, iPads or iPods`,
                `If you have an Apple device, you can opt out of interest-based advertising by updating to iOS 6.0 or higher, and setting Limit Ad Tracking to ‘ON’. On an iPhone 6 for example, you can do this by clicking on Settings -> General -> Privacy -> Advertising and toggling “Limit Ad Tracking” to ‘ON’. Our systems are designed to respect your choice and not use your personal information to provide interest-based advertising when this setting is ON.`,
                `Opting Out on Android Devices`,
                `If you have an Android device and would like to opt out of interest-based advertising, you may do this as follows:`,
                `Open the Settings app on your device`,
                `Go to “Google”`,
                `Select Ads`,
                `Opt out of interest-based ads`,
                `More information on our use of cookies on our websites can be found in our Cookie Policy.`,
            ]
        },
        {
            'title':'5.HOW WE USE INFORMATION',
            'content':[
                `The main reason we use your information is to deliver and improve our services. Additionally, we may use your information to help keep you safe and to communicate with you. Read on for a more detailed explanation of the various reasons we use your information, together with practical examples.`,
                `To administer your account and provide our services to you`,
                `Deliver our services to you and ensure their optimal functionality and operation.`,
                `Identify, establish and manage your account and our relationship with you.`,
                `Provide you with customer support and respond to your requests.`,
                `Should you provide us with this information, we may use your email address, name, country and language for customized marketing purposes.`,
                `When we collect your precise geolocation data (subject to your consent) (which may be via the device’s cellular, Wi-Fi, Global Positioning System (GPS) networks and/or Bluetooth information), we do so to provide you with our location-related products and services, for example to provide you with forecast and weather alerts for your location, and also to facilitate, measure, or otherwise support advertising by third parties (through our apps or third parties’ apps) that may be related to your location over time.`,
                `Some of our apps may offer you the ability to register and login using your third party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the app you are using, and on the social media provider concerned, but may often include your name, e-mail address, mobile phone number and friends list. If you login using your Facebook account, your e-mail or your phone number then we may receive a unique ID in connection with this login. This is solely used for confirmation purposes and is kept separate from all other online identifiers. We will use the information we receive only for the purposes that are described in this Privacy Policy or that are otherwise made clear to you in the app you are using. Please note that we do not control, and are not responsible for, other uses of your personal information by your third party social media provider. We recommend that you see their privacy policy to understand how they collect, use and share your information, and how you can set your privacy preferences on their sites and apps.`,
                `If you have registered with an app using your social media account details, we may use the information we receive from your social media provider to identify which of your friends are also using our services so that you can connect with them in our app.`,
                `In some services, to ensure a consistent experience across your devices`,
                `Link the various apps and devices you use, so that you can enjoy a consistent experience of our services on all of them. This will allow us to form a single view of your use of our services, so you can easily access your content on multiple devices. For this purpose and better user experience generally, we may use and combine your personal information which we collect across different services used by you.`,
                `To improve our services and develop new ones`,
                `Develop new features and services (for example, we may decide to build a new feature further to requests or feedback received from users).`,
                `Clean Phone uses the Google Analytics tool Firebase in order to carry out analysis on information which is gathered by our apps. Device information and other information which we gather are provided to Google in order to enable them to carry out data analysis. Any information which is provided to Google will become subject to Google’s privacy policy which is located here: `,
                `www.google.com/privacy.html. For further information on how Google’s Firebase analytics tool uses data, please see the site “How Google uses data when you use our partners’ sites or apps”, (located at www.google.com/policies/privacy/partners/).`,
                `Conduct research and analysis of your behaviour about your use of, or interest in, our products, services, or content to improve our services and content (for instance, we may decide to change the look and feel or even substantially modify a given feature based on users’ behaviour). This software may record information such as how often you use the services, what happens within the services, aggregated usage, performance data, app errors and debugging information, and where the apps were downloaded from.`,
                `To communicate with you`,
                `Communicate with you by postal mail, telephone and/or mobile devices (including through in-application advertising) about products or services that may be of interest to you either from us, our parent or subsidiary companies or other third parties. If you do not wish to receive in-application advertising, in some cases you can upgrade to the paid version of the app.`,
                `When you share your information with us via a social network provider like Facebook, we use this to show information on your social network profile (subject to your privacy setting and preferences for such social network) and to allow you to share information about our services with your connections or on your social network profile.`,
                `With your consent (where needed), to send you email marketing in order to provide you with information about services that you may find of interest. You can opt-out of receiving direct marketing communications from us by following the unsubscribe instructions included in our marketing communications. We also send you non-marketing emails or messages, such as those related to transactions, your account, security, or product changes. `,
                `Examples of service-related messages include an email address confirmation/welcome email when you register your account, service availability or modification of key features or functions.`,
                `To serve you relevant offers and ads`,
                `Develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness. When you are on our services, third-party ad partners may provide you with advertising based upon your interests. These companies may use technologies to collect data about users, including precise device location data, to help them tailor relevant ads and measure ads’ effectiveness. These companies may collect this data through SDKs or from users who view or interact with their advertisements. For the purposes of European law, most of these ad partners are independent controllers of your information, which means that they are responsible for providing and complying with their own policies relating to any personal information they obtain in connection with the services. It also means that if you are a Facebook app user for example – you provided consent to Facebook in your Facebook app to allow them to show you personalized ads inside of our services.`,
                `Verify your eligibility and deliver prizes in connection with contests and sweepstakes and in order to conduct polls and surveys;`,
                `To prevent, detect and fight fraud or other illegal or unauthorized activities`,
                `Perform data analysis to better understand and design countermeasures against these activities`,
                `Retain data related to fraudulent activities to prevent against recurrences`,
                `To ensure legal compliance`,
                `Comply with legal requirements`,
                `Assist law enforcement`,
                `Enforce or exercise our rights, for example our EULA`,
                `To process your information as described above, we rely on the following legal bases:`,
                `Provide our service to you: Most of the time, the reason we process your information is to perform the contract that you have with us. For instance, as you go about using our service, we use your information to maintain your account and provide our cool features to you.`,
                `Legitimate interests: We may use your information where we have legitimate interests to do so. For instance, we analyze users’ behavior on our services to continuously improve our offerings and we process information for administrative, fraud detection and other legal purposes.`,
                `Consent: From time to time, we may ask for your consent to use your information for certain specific reasons. You may withdraw your consent at any time where indicated in the services or by contacting us at the address provided at the end of this Privacy Policy.`,
            ]
        },
        {
            'title':'6.HOW WE SHARE YOUR INFORMATION',
            'content':[
                `Since our goal is to provide the best services we can to you, the main sharing of users’ `,
                `information is with service providers and partners who assist us in operating the services, with other IAC Group companies and, in some cases, legal authorities. Read on for more details about how your information is shared with others.`,
                `With our service providers and partners`,
                `We follow a strict vetting process prior to engaging any service provider or working with any partner. We use third parties to help us operate and improve our services. These third parties assist us with various tasks, including data hosting and maintenance, analytics, marketing, advertising and security operations. These service providers may have limited access to your information, which is needed to perform their functions, but are not permitted to share or use such information for any other purposes. For example, we use your non-personal information (meaning information that, by itself, does not identify who you are such as device information) for targeted online marketing through tools like Facebook Custom Audience to contact interested parties.`,
                `In addition, we may share aggregated information we collect, under any of the above circumstances, with third parties, including advisors, advertisers and investors, for the purpose of conducting general business analysis or other legitimate business purposes. Please note that such aggregated information will not include any information which allows third parties to identity you.`,
                `We might share your information with other companies who:`,
                `assist us in technical processing operations, such as data hosting and maintenance, finance, legal, HR and accounting assistance, securing our data and systems and fighting against spam, abuse, fraud, infringement and other wrongdoings.`,
                `• for legitimate business purposes such as corporate audit, analysis and consolidated reporting as well as compliance with applicable laws.`,
                `For corporate transactions`,
                `We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.`,
                `When required by law`,
                `We may disclose your information if reasonably necessary: (i) to comply with a legal process, `,
                `We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties and/or to comply with applicable law; (iii) to enforce our agreements or policies with you; and (iv) to investigate, prevent, or take other `,
                `action regarding illegal activity, suspected fraud or other wrongdoing.`,
                `With your consent or at your request`,
                `We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the information.`,
            ]
        },
        {
            'title':'7.HOW WE PROTECT YOUR INFORMATION',
            'content':[
                `The security of your personal information is important to us. We follow generally accepted standards to protect the personal information submitted to us, both during transmission and after it is received. We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of your personal information. As with all technology companies, although we take steps to secure your information, we do not promise, and you should not expect, that your personal information will always remain secure. `,
                `We regularly monitor our systems for possible vulnerabilities and attacks and regularly review our information collection, storage and processing practices to update our physical, technical and organizational security measures.`,
                `We may suspend your use of all or part of the services without notice if we suspect or detect any breach of security. If you believe that your account or information is no longer secure,please notify us immediately at cleanphone.feedback@gmail.com`,
            ]
        },
        {
            'title':'8.INFORMATION RELATING TO CHILDREN',
            'content':[
                `Our services are intended for general audiences over the age of 13 years old (or over the age of 16 if you are resident in the EEA). We do not knowingly collect information from children under the age of 13 years old, or 16 years old in the EEA. If you are not over 13 years old (or 16 years old in the EEA) then DO NOT DOWNLOAD OR USE THE SERVICES. If you believe that we may have personal information from or about a child under the age of 13 years old (or over the age of 16 if you are resident in the EEA), please contact us at cleanphone.feedback@gmail.com and we will promptly delete that personal information`,
            ]
        },
        {
            'title':'9.YOUR RIGHTS',
            'content':[
                `We want you to be in control of your information and be aware of your privacy rights, so here are a few key points to remember:`,
                `Depending on your location you may have some or all of the following rights in relation to how we use your personal information:`,
                `• Access: you may request access to your personal information and receive copies of it;`,
                `• Correction: you may have inaccurate/incomplete personal information corrected and updated;`,
                `• Object to, or Limit or Restrict, Use of Data: you can ask us to stop using all or some of your personal information or to limit our use of it.`,
                `• Deletion: in certain circumstances, you can request a right “to be forgotten” (this is a right to have your information deleted or our use of your data restricted). We will honour such requests unless we have to retain this information to comply with a legal obligation or unless we have an overriding interest to retain it.`,
                `• Portability: in certain circumstances, exercise the right to data portability (this is a right to obtain a transferable version of your personal information to transfer to another provider)`,
                `• Consent Management: where we rely on consent to process your personal data, you may withdraw consent at any time. You do not have to provide a reason for your withdrawal where processing is based on consent.`,
                `If you wish to exercise any of these rights, or wish to object to our use of your information, please write to us at the address listed below and we will consider your request in accordance with applicable laws.`,
                `Keep in mind, we may reject requests for certain reasons, including if the request is unlawful or if it may infringe on the rights and freedoms of another person. For example, we may not be able to accommodate certain requests to object to the processing of personal information, notably where such requests would not allow us to provide our service to you anymore.`,
                `For your protection, we may ask you to provide proof of identity before we can answer the above requests.`,
            ]
        },
        {
            'title':'10.CROSS-BORDER DATA TRANSFERS',
            'content':[
                `Sharing of information sometimes involves cross-border data transfers, for instance to the United States of America and other jurisdictions. The United States, European Economic Area (“EEA”) Member States, and other countries all have different laws. When your information is moved from your home country to another country, the laws and rules that protect your personal information in the country to which your information is transferred may be different from those in the country in which you live.`,
                `The European Commission has adopted standard contractual clauses (also known as Model Clauses), which provide safeguards for personal information that is transferred outside of the EEA. We use these Model Clauses or other suitable safeguards (such as EU-US Privacy Shield to send personal information to some of our third party service providers in the United States, where they are certified to receive such information under the Privacy Shield Program) to permit data transfers from the EEA to other countries.`,
            ]
        },
        {
            'title':'11.HOW LONG WE STORE YOUR INFORMATION',
            'content':[
                `We retain your information only for as long as is necessary for the purposes set out in this policy, `,
                `for as long as your account is active or as needed to provide the services to you. We will also `,
                `retain and use your information to the extent necessary:`,
                `to comply with applicable law;`,
                `to evidence our compliance with applicable law;`,
                `if there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved; or`,
                `if the information must be kept for our legitimate business interests, such as enforcing our terms `,
                `or fraud prevention. For example, information may need to be kept to prevent a user who was `,
                `banned for security incidents from opening a new account.`,
                `Keep in mind that even though our systems are designed to carry out data deletion processes `,
                `according to the above guidelines, we cannot promise that all data will be deleted within a `,
                `specific timeframe due to technical constraints.`,

            ]
        },
        {
            'title':'12.PRIVACY POLICY CHANGES',
            'content':[
                `Because we’re always looking for new and innovative ways to help you enjoy our services, this policy may change over time. We will notify you of any material changes so that you have time to review the changes.`,
            ]
        },
        {
            'title':'13.DATA PROTECTION OFFICER',
            'content':[
                `For our European users, our Data Protection Officer is responsible for ensuring your rights are respected and to review and oversee how we collect and use your personal information. They can be reached by contacting sharpforksltd@gmail.com`,
            ]
        },
        {
            'title':'14.HOW TO CONTACT US',
            'content':[
                `If you have questions about this Privacy Policy, here’s how you can reach us:`,
                `cleanphone.feedback@gmail.com`,
            ]
        },
    ],
    'tw':[
        {
            "topTitle": "《少年西行記》隱私政策"
        },
        {
            "title": '前言',
            "content": [
                '本政策是由我方平臺（以下稱為「甲方」）就甲方在其和/或其關聯公司的移動手機遊戲平臺上所提供的產品和服務（包括甲方的網站以及甲方現在正在提供和將來可能向用戶提供的遊戲服務和其他網絡服務，以下統稱為「產品和服務」）所製定的。',
                '最近更新日期：2022年1月29日',
                `本政策將幫助您了解以下內容：`,
                `一、我們如何收集和使用個人信息`,
                `二、我們如何使用 Cookie 和同類技術`,
                `三、我們如何存儲個人信息`,
                `四、我們如何共享、轉讓、公開披露個人信息`,
                '五、第三方服務',
                `六、我們如何處理未成年人的個人信息`,
                `七、您的個人信息如何在全球範圍轉移`,
                `八、本政策可能變更`,
                `九、如何聯系我們`,
                '在使用我們的服務前，您可以通過閱讀完整版《少年西行記隱私政策》，了解個人信息類型與用途的對應關系等更加詳盡的個人信息處理規則，請您務必仔細閱讀並透徹理解本政策，您應重點閱讀並在確認充分理解及同意後再開始使用。如果您繼續使用我們的服務，即表示您同意我們按照本政策收集、使用、儲存和披露您的相關信息。',
                '請註意，甲方可能適時對本協議進行修改與更新，您同意將隨時留意查看本協議的最新版本。請在使用我們的產品（或服務）前，仔細閱讀並了解本《隱私政策》，以確保對本《隱私政策》的最新版本始終保持了解。',
            ]
        },
        {
            'title': '一、我們如何收集和使用個人信息',
            "content": [
                '1.個人信息',
                '當您使用服務時，我們會收集您的網絡身份標識信息及個人常用設備信息，用於標記您為少年西行記的用戶。為完成創建賬號所填寫的信息，例如：您的手機號碼、身份證、創建的用戶名。第三方可能會獲取您的社交帳號信息。',
                '2.為您提供您可能感興趣的廣告、資訊及產品信息',
                '為向您提供與您更加相關的廣告以替代普遍投放的廣告 ，我們可能會收集和使用您在甲方的操作記錄和部分必要的設備信息（例如：您的唯一設備識別碼），以及您在使用我們服務時瀏覽或要求提供的其他信息和內容詳情、遊戲賬號交易信息。',
                '3.支付結算',
                '為便於您在支付相關訂單時，綜合判斷您賬戶及交易風險、進行身份驗證、檢測及防範安全事件，我們可能會在交易過程中收集您的虛擬財產相關信息（僅限交易記錄、虛擬貨幣、虛擬交易、遊戲類兌換碼等）。',
                '4.消費記錄',
                '當您使用少年西行記產品的消費功能時，我們可能會收集您的充值記錄、消費記錄信息，以便您查詢您的交易記錄，同時盡最大程度保護您的虛擬物品安全。充值記錄、消費記錄屬於敏感信息，收集上述信息為實現少年西行記的消費功能所必須，否則將無法完成交易。',
                '5.產品開發和服務優化。',
                '例如，為修復系統故障並優化我們的服務，我們可能需記錄和分析系統故障時產生的信息。',
                '6.可能會收集的信息',
                '為保障您正常使用我們的服務，維護遊戲基礎功能的正常運行，優化遊戲產品性能，提升您的遊戲體驗並保障您的帳號安全，我們可能會收集：唯一設備識別符（IMEI）、MAC地址、廣告標識符、Serial、設備機型、設備名稱、操作系統及版本、設備分辨率、設備設置、軟硬件特征信息、設備屏幕尺寸、移動網絡信息、登錄IP地址、日誌信息（包括操作日誌、服務日誌）、CPU信息、軟件信息。用於識別帳號異常狀態，了解產品適配性，維護基礎功能的正常運行，實現本遊戲的問題排查。為了收集上述基本的 個人設備信息，我們將會申請訪問您的設備信息權限，我們收集這些信息是為了向您提供本遊戲的核心遊戲功能。拒絕提供上述信息將可能使您無法正常體驗本遊戲的功能。',
                '7.第三方收集的信息',
                '請您理解，在從第三方接收您的個人信息前，我們無權決定且無法知悉第三方獲取您個人信息的範圍，如第三方開展業務所進行的個人信息處理活動超出您向我們提供個人信息時的授權同意範圍、或第三方違法違規收集您的個人信息（如渠道違反必要原則，收集您的通訊錄信息），責任應由第三方承擔，請您立即和我們取得聯系，以便我們及時通知第三方采取相應措施以避免或降低相關損失。',
            ]
        },
        {
            "title": "二、我們如何使用 Cookie 和同類技術",
            "content": [
                '為確保網站正常運轉，我們可能會在您的計算機或移動設備上存儲名為 Cookie 的小數據文件。Cookie 通常包含標識符、站點名稱以及一些號碼和字符。 Cookie有助於我們辨認您作為我們的註冊用戶的身份，或保存您向我們提供有關您的喜好或其他信助於我們辨認您作為我們的註冊用戶的身份，或保存您向我們提供有關您的喜好或其他信息了解您使用服務進行什麽活動、或哪些服務或服務最受歡迎；及有助於我們根據您的信息，向您提供與您相關的廣告而非進行普遍的廣告投放。',
                '少年西行記上可能會有廣告商和其他合作方放置的COOKIES或同類技術。這些COOKIES和或同類技術可能會收集與您相關的非個人身份信息，以用於分析用戶如何使用該等服務、向您發送您可能感興趣的廣告，或用於評估廣告服務的效果。這些第三方COOKIES或同類技術收集和使用該等信息不受本政策約束，而是受到其自身的信息保護聲明約束，我們不對第三方的COOKIES或同類技術承擔責任。',
            ]

        },
        {
            "title": "三、我們如何共享、轉讓、公開披露您的個人信息",
            "content": [
                '（一）共享',
                '您理解並同意，經過去標識化處理後的信息將無法識別您的身份，我們可能在以下情況下與甲方以外的任何公司、組織和個人分享您的個人信息：',
                '1.與關聯方的必要分享。為便於我們基於統一的帳號體系向您提供一致化服務以及便於您進行統一管理、個性化推薦、保障系統和帳號安全等，您的個人信息可能會在我們和我們的關聯方之間進行必要共享；',
                '2.我們可能會根據法律法規規定，或按政府主管部門的強製性要求，對外共享您的個人信息。',
                '3.向委托我們進行推廣的合作夥伴等第三方共享，目的是為了使該等委托方了解推廣的覆蓋面和有效性。比如我們可以告知該委托方有多少人看了他們的推廣信息或在看到這些信息後購買了委托方的商品，或者向他們提供不能識別個人身份的統計信息，幫助他們了解其受眾或顧客；',
                '4.與授權合作夥伴共享：僅為實現本政策中聲明的目的，我們的某些服務將由授權合作夥伴提供。我們可能會與合作夥伴共享您的某些個人信息，以提供更好的客戶服務和用戶體驗。我們僅會出於合法、正當、必要、特定、明確的目的共享您的個人信息，並且只會共享提供服務所必要的個人信息。',
                '（二）轉讓',
                '我們不會將您的個人信息轉讓給任何公司、組織和個人，但以下情況除外：',
                '1.在獲取明確同意的情況下轉讓：獲得您的明確同意後，我們會向其他方轉讓您的個人信息；',
                '2.在涉及合並、收購或破產清算時，如涉及個人信息轉讓，我們將通過推送通知、公告或其他方式另行告知您相關情形，按照法律法規所要求的標準繼續保護或要求新的管理者按照本政策繼續保護您的個人信息。',
                '3.部分場景下，您可以使用相同的賬號及密碼在其他第三方平臺登錄、使用，以獲得更好的遊戲體驗。為提供此項服務，我們可能需要向第三方傳送您的個人信息及遊戲信息，我們將按照法律法規所要求的標準繼續保護或要求新的管理者按照本政策繼續保護您的個人信息。',
                '（三）公開披露',
                '我們僅會在以下情況下，公開披露您的個人信息：',
                '1.獲得您明確同意後；',
                '2.與國家安全、國防安全有關的；',
                '3.與公共安全、公共衛生、重大公共利益有關的；',
                '4.與犯罪偵查、起訴、審判和判決執行等有關的；',
                '5.出於維護信息主體或其他個人的生命、財產等重大合法權益但又很難得到您本人同意的；',
                '6.所收集的信息是您自行向社會公眾公開的；',
                '7.從合法公開披露的信息中收集信息的，如合法的新聞報道、政府信息公開等渠道；',
                '8.根據您的要求簽訂合同所必需的；',
                '9.用於維護甲方服務的安全穩定運行所必需的，例如發現、處置產品或服務的故障；',
                '10.為合法的新聞報道所必需的；',
                '11.學術研究機構基於公共利益開展統計或學術研究所必要，且對外提供學術研究或描述的結果時，對結果中所包含的信息進行去標識化處理的；',
                '12.法律法規規定的其他情形。',
            ]

        },
        {
            "title": "四、我們如何保護您的個人信息",
            "content": [
                '（一）我們會采取一切合理可行的措施，保護您的個人信息。例如，我們使用SSL/hash對您的數據進行加密保護；如我們與第三方分享您的信息，我們可能將會采用加密、脫敏及其他去標識化處理等手段保障您的信息安全。',
                '（二）我們僅允許有必要知曉這些信息的甲方工作人員、合作夥伴訪問您的信息，同時要求可能接觸到您的信息的所有人員履行相應的保密義務。如果未能履行這些義務，可能會被追究法律責任或被中止與甲方的合作關系。',
                '（三）我們會采取一切合理可行的措施，確保未收集無關的個人信息。我們只會在必要的合理期限內保留您的個人信息，除非需要延長保留期或受到法律的允許。',
                '（四）互聯網並非絕對安全的環境，而且電子郵件、即時通訊、及與其他甲方用戶的交流方式並未加密，我們強烈建議您不要通過此類方式發送個人信息。請使用復雜密碼，協助我們保證您的賬號安全。請您務必妥善保管好您的帳號、密碼及其他身份要素。您在使用少年西行記時，我們會通過您的帳號、密碼及及其他身份要素來識別您的身份。',
                '（五）互聯網環境並非百分之百安全，我們將盡力確保或擔保您發送給我們的任何信息的安全性。如果我們的物理、技術、或管理防護設施遭到破壞，導致信息被非授權訪問、公開披露、篡改、或毀壞，導致您的合法權益受損，我們將承擔相應的法律責任。',
                '（六）請您理解，由於技術的限製以及風險防範的局限，即便我們已經盡量加強安全措施，也無法始終保證信息百分之百的安全。您需要了解，您接入少年西行記服務所用的系統和通訊網絡，有可能因我們可控範圍外的情況而發生問題。',
            ]

        }, {
            "title": "五、第三方服務",
            "content": [
                '少年西行記可能鏈接至第三方提供的社交媒體或其他服務（包括網站或其他服務形式）。包括：（1）您可利用「分享」鍵將某些內容分享到第三方服務，或您可利用第三方服務登錄少年西行記。這些功能可能會收集您的信息（包括您的日誌信息），並可能在您的電腦裝置COOKIES，從而正常運行上述功能； （2）我們通過廣告或我們服務的其他方式向您提供鏈接，使您可以鏈接至第三方的服務或網站； （3）其他接入第三方服務的情形。包括：為實現本政策中聲明的目的，我們可能會接入第三方SDK服務，並將我們依照本政策收集的您的個人信息如社交媒體信息，共享給該等第三方，以便提供更好的客戶服務和用戶體驗。',
                '該等第三方社交媒體或其他服務由相關的第三方負責運營。您使用該等第三方的社交媒體服務或其他服務（包括您向該等第三方提供的任何信息），須受第三方自己的服務條款及信息保護聲明（而非本政策）約束，您需要仔細閱讀其條款。本政策僅適用於我們所收集的個人信息，並不適用於任何第三方提供的服務或第三方的信息使用規則。 您發現這些第三方社交媒體或其他服務存在風險時，建議您終止相關操作以保護您的合法權益。。友盟隱私政策鏈接：https://www.umeng.com/page/policy'
            ]

        },
        {
            "title": "六、我們如何處理未成年人的個人信息",
            "content": [
                '我們的產品、網站和服務主要面向成人。甲方遵從國家政策，如果沒有父母或監護人的同意，未成年人不得創建自己的用戶賬戶。對於經父母或監護人同意而收集未成年人個人信息的情況，我們只會在受到法律的允許、父母或監護人明確同意或者保護未成年人所必要的情況下使用或公開披露此信息。如發現在未事先獲得法定監護人同意的情況下，我們可能收集了未成年人的個人信息，我們會對該等數據和個人信息盡快進行刪除等操作處理。',
                '若您是未成年人的監護人，當您對您所監護的未成年人的個人信息有相關疑問時，請及時與我們的客服聯系。',
            ]

        },
        {
            "title": "七、您的個人信息如何在全球範圍轉移",
            "content": [
                '原則上，我們在中華人民共和國境內收集和產生的個人信息，將存儲在中華人民共和國境內。',
                '我們可能通過遍布全球的資源和服務器提供產品或服務，這意味著，在獲得您的授權同意後，您的個人信息可能會被轉移到您使用產品或服務所在國家/地區的境外管轄區，或者受到來自這些管轄區的訪問。',
                '境外管轄區可能有不同的個人信息保護法，我們將對信息進行加密傳輸，並盡商業上合理努力督促第三方在使用您的個人信息時遵守其適用的法律法規及協議約定的保密和安全措施。',
            ]

        },
        {
            "title": "八、本政策可能變更",
            "content": [
                '為了給您提供更好的服務，我們可能會根據產品或服務的更新情況及法律法規的相關要求適時修改本政策的條款，該等修改構成本政策的一部分。如該等更新造成您在本政策下權利的實質減少或重大變更，我們將在本政策生效前通過網站公告、推送通知、彈窗提示或其他方式來通知您，您如果不同意該等變更，可以選擇停止使用少年西行記；如您仍然繼續使用少年西行記，即表示您已充分閱讀、理解並同意受經修訂的本政策的約束。',
            ]

        },
        {
            "title": "九、如何聯系我們",
            "content": [
                '我們設立了個人信息保護專職部門，將按照本政策保護您的個人信息。如您有關於網絡信息安全的投訴和舉報，或您對本政策、您的信息的相關事宜有任何問題、意見或建議，請與少年西行記的個人信息保護工作人員聯系，我們將在15個工作日內回復。',
                '客服QQ：485729307 ',
                '聯系郵箱：485729307@qq.com',
                '官方交流群: 220714787'
            ]
        },
    ]
};

export { privacyData };
